import React, { useState } from 'react';

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    course: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const response = await fetch('https://formspree.io/f/xkgwdbrq', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    if (response.ok) {
      setIsSubmitted(true);
      setFormData({ name: '', email: '',contactNumber: '', course: '', message: '' });
    } else {
      setError('Failed to send message. Please try again.');
    }
  };

  return (
    <div>
      <h1 className='enquiry-heading'>Enquire Us</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <label htmlFor="contactNumber">Contact Number:</label>
        <input
          type="tel"  // Corrected input type for phone numbers
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleInputChange}
          required
        />
        
        <label htmlFor="course">Select Course:</label>
        <select
        name="course"
        value={formData.course}
        onChange={handleInputChange}
        required
        >
        <option value="">-- Select a Course --</option>
        <option value="Web Development">Web Development</option>
        <option value="Data Science">Data Science</option>
        <option value="Cybersecurity">Cybersecurity</option>
        <option value="AI & ML">AI & Machine Learning</option>
        </select>
        <label htmlFor="message">Message</label>
        <textarea
          name="message"
          rows="5"
          value={formData.message}
          onChange={handleInputChange}
          required
        ></textarea>
        <button type='submit'>Send</button>
        
      </form>

      {isSubmitted && <p>Thank you! Your message has been sent. Our Team will connect as soon as poosible.</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );

  
};


export default EnquiryForm;
